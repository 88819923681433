
import logo from "./logo.svg";
import "./App.css";
import Login from "./Pages/Public/Login";
import Register from "./Pages/Public/Register";
import Cookies from "universal-cookie";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import Dashboard from "./Pages/Private/Dashboard";
import Landing from "./Pages/Public/Landing";
import ChoosePlans from "./Pages/Private/Settings/Payments/ChoosePlans";
import ViewPlans from "./Pages/Private/Settings/Payments/ViewPlans";
import SideBar from "./Components/SideBar";
import UserSettings from "./Pages/Private/Settings/UserSettings";
import BillingController from "./Pages/Private/Settings/Payments/BillingController";
import ViewSubscriptions from "./Pages/Private/Settings/Payments/ViewSubscriptions";
import EmailConfirmation from "./Pages/Public/EmailConfirmation";
import RequestPasswordChange from "./Pages/Public/RequestPasswordChange";
import PasswordChange from "./Pages/Public/PasswordChange";
import CookieConsent from "react-cookie-consent";
import AccountSettings from "./Pages/Private/Settings/Account/AccountSettings";
import NotFound from "./Pages/Public/NotFound";
import Onboarding from "./Pages/Private/Settings/Onboarding/Onboarding";
import Team from "./Pages/Private/Settings/Team/Team";
import Audio from "./Pages/Private/Settings/Audio/Audio";
import Meetings from "./Pages/Private/Settings/Meetings/Meetings";
import Meeting from "./Pages/Private/Settings/Meetings/Meeting";
import LiveAudioRecord from "./Components/Audio/LiveAudioRecord";
import PricingPage from "./Pages/Private/Settings/Billing/PricingPage";
import RegisterByLink from "./Pages/Public/RegisterByLink";
import { useTranslation } from "react-i18next";
import DownloadDocument from "./Pages/Private/Settings/TC&PP/DownloadDocument";
import PublicMeeting from "./Pages/Private/Settings/PublicMeetings/PublicMeeting";
import Analysis from "./Pages/Private/Settings/Analysis/Analysis";
import Analys from "./Pages/Private/Settings/Analysis/Analys";
import Legislativas2024 from "./Pages/Public/Legislativas2024";
import Legislativas2024Debate from "./Pages/Public/Legislativas2024Debate";
import Projects from "./Pages/Private/Settings/Projects/Projects";
import DetailProject from "./Pages/Private/Settings/Projects/DetailProject";
import NewAnalysisHolder from "./Pages/Private/Settings/NewAnalysis/NewAnalysisHolder";
import TextCoder from "./Pages/Private/Settings/TextCoder/TextCoder";
import { clarity } from 'react-microsoft-clarity';
import { useEffect } from "react";
import RewardsPage from "./Pages/Private/Settings/Billing/RewardsPage";

function PrivateOutlet({ children }) {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return !!token ? children : <Navigate to="/login" />;
}

function PublicOutlet({ children }) {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return !!token ? <Navigate to="/dashboard" /> : children;
}

function CookieWarning() {
  const { t } = useTranslation()
  const cookies = new Cookies();
  const cookieConsent = cookies.get("cookieConsent");
  if (!cookieConsent) {
    return <CookieConsent
      location="bottom"
      buttonText={t('Cookies.Button')}
      cookieName="Consent_cookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ background: "#4f46e5", color: "white", fontSize: "13px" }}
    >
      {t('Cookies.Message')}{" "}
    </CookieConsent>
  }
}

function App() {
  const cookies = new Cookies();
  const token = cookies.get("token");

  useEffect(() => {
    const cookies = new Cookies();
    clarity.init(process.env.REACT_APP_CLARITY_KEY);
    clarity.identify('USER_ID', { userProperty: 'value' });
    clarity.consent();
    clarity.setTag('key', 'value');
    clarity.upgrade('upgradeReason');
    if (clarity.hasStarted()) {
      clarity.identify(token, { userProperty: 'value' });
    }

  }, [])

  return (
    <BrowserRouter>
      <CookieWarning />
      <Routes >
        <Route path="/" element={<PublicOutlet><Login /></PublicOutlet>} />
        <Route path="/:join" element={<PublicOutlet><Login /></PublicOutlet>} />
        <Route path="/login" element={<PublicOutlet><Login /></PublicOutlet>} />
        <Route path="/login/:language" element={<PublicOutlet><Login /></PublicOutlet>} />
        <Route path="/register" element={<PublicOutlet><Register /></PublicOutlet>} />
        <Route path="/register/:language" element={<PublicOutlet><Register /></PublicOutlet>} />
        <Route path="/register/:language/:invite_uuid" element={<PublicOutlet><Register /></PublicOutlet>} />
        <Route path="/register_by_link/:confirmation_token" element={<PublicOutlet>
          <RegisterByLink />
        </PublicOutlet>} />
        <Route path="/legislativas-2024" element={
          <Legislativas2024 />
        } />
        <Route path="/debate-partidos-com-assento-parlamentar" element={
          <Legislativas2024Debate />
        } />
        <Route path="/email_confirmation" element={<EmailConfirmation />} />
        <Route path="/request_password_change" element={<RequestPasswordChange />} />
        <Route path="/change_password" element={<PasswordChange />} />
        <Route path="/onboarding" element={<Onboarding />} />

        <Route path="/downloads/:type/:language" element={<DownloadDocument />} />


        <Route
          path="/dashboard"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Dashboard"} />
              <Dashboard />
            </PrivateOutlet>
          }
        />
        <Route
          path="/dashboard/:tutorial"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Dashboard"} />
              <Dashboard />
            </PrivateOutlet>
          }
        />

        <Route
          path="/team"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Team"} />
              <Team />
            </PrivateOutlet>
          }
        />



        <Route
          path="/audio"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Audio"} />
              <Audio />
            </PrivateOutlet>
          }
        />

        <Route
          path="/record"
          element={
            <PrivateOutlet>
              <LiveAudioRecord />
            </PrivateOutlet>
          }
        />

        <Route
          path="/meetings"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Meetings"} />
              <Meetings />
            </PrivateOutlet>
          }
        />

        <Route
          path="/projects"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Projects"} />
              <Projects />
            </PrivateOutlet>
          }
        />

        <Route
          path="/project/:project_uuid"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Projects"} />
              <DetailProject />
            </PrivateOutlet>
          }
        />

        <Route
          path="/meeting/public/:public_uuid"
          element={
            <PublicMeeting />
          }
        />

        <Route
          path="/meeting/:meeting_uuid"
          element={
            <PrivateOutlet>
              <SideBar />
              <Meeting />
            </PrivateOutlet>
          }
        />

        {/*Settings */}
        {/*   Account */}
        <Route
          path="/text_coder/:project_uuid/file/:analysis_uuid"
          element={
            <PrivateOutlet>
              <TextCoder />
            </PrivateOutlet>
          }
        />
        <Route
          path="/text_coder/:project_uuid"
          element={
            <PrivateOutlet>
              <TextCoder />
            </PrivateOutlet>
          }
        />

        <Route
          path="/analysis"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Analysis"} />
              <Analysis />
            </PrivateOutlet>
          }
        />
        <Route
          path="/analysis/:analysis_uuid"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Projects"} />
              <NewAnalysisHolder view_mode={"private"} />
            </PrivateOutlet>
          }
        />
        <Route
          path="analysis/public/:analysis_uuid"
          element={
            <PrivateOutlet>
              <NewAnalysisHolder view_mode={"public"} />
            </PrivateOutlet>
          }
        />

        <Route
          path="/deprecated_analysis/:analysis_uuid"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Analysis"} />
              <Analys />
            </PrivateOutlet>
          }
        />

        <Route
          path="/account_settings"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Settings"} />
              <AccountSettings />
            </PrivateOutlet>
          }
        />
        {/*   Billing*/}
        <Route
          path="/empty_subscriptions"
          element={
            <PrivateOutlet>
              <SideBar />
              <UserSettings tabName="Plans">
                <BillingController />
              </UserSettings>
            </PrivateOutlet>
          }
        />
        <Route
          path="/view_subscriptions"
          element={
            <PrivateOutlet>
              <SideBar />
              <UserSettings tabName="Plans">
                <BillingController>
                  <ViewSubscriptions />
                </BillingController>
              </UserSettings>
            </PrivateOutlet>
          }
        />

        <Route
          path="/rewards"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Rewards"} />
              <RewardsPage />
            </PrivateOutlet>
          }
        />

        <Route
          path="/pricing"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Credits"} />
              <PricingPage />
            </PrivateOutlet>
          }
        />
        <Route
          path="/pricing/:tab"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Credits"} />
              <PricingPage />
            </PrivateOutlet>
          }
        />
        <Route
          path="/pricing/:tab/:status"
          element={
            <PrivateOutlet>
              <SideBar current_tab={"Credits"} />
              <PricingPage />
            </PrivateOutlet>
          }
        />

        <Route
          path="/plans"
          element={
            <PrivateOutlet>
              <SideBar />
              <UserSettings tabName="Plans">
                <BillingController>
                  <PricingPage />
                </BillingController>
              </UserSettings>
            </PrivateOutlet>
          }
        />

        <Route
          path="/payment_option_details"
          element={
            <PrivateOutlet>
              <SideBar />
              <UserSettings tabName="Plans">
                <BillingController forceRefresh={true}>
                  <ChoosePlans />
                </BillingController>
              </UserSettings>
            </PrivateOutlet>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

    </BrowserRouter>
  );
}
//<BrowserRouter basename={window.location.pathname || ''}>
//<Route exact path="/login" element={Boolean(token)?<Login/>:<Navigate to="/register" replace/>}/>
export default App;
