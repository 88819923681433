import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../../../../Components/GeneralComponents/SectionHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { getProject } from '../../../../Requests/ProjectRequests';
import CreateEditProjectSlideover from '../../../../Components/Projects/CreateEditProjectSlideover';
import { Card } from '@tremor/react';
import NewAudioFileUploadModal from '../../../../Components/Files/NewAudioFileUploadModal';
import AnalisysCard from '../../../../Components/Files/AnalisysCard';
import ProjectGpt from '../../../../Components/ProjectGpt/ProjectGpt';
import UploadFileModal from '../../../../Components/Text/TextUploadModal';
import UploadFileWithTypes from '../../../../Components/NewAnalysisElements/UploadFileWithTypes';
import { CheckIcon, DocumentArrowUpIcon, TagIcon, XMarkIcon } from '@heroicons/react/24/outline';
import empty_file_cabinet from '../../../../Assets/Project/empty_file_cabinet.png'
import text_coder from '../../../../Assets/TextCoder/text_coder.png'
import TextCodeModal from '../../../../Components/TextCoder/TextCodeModal';
import { getTextCodes } from '../../../../Requests/TextCoderRequests';
import TextCodeExcerptsModal from '../../../../Components/TextCoder/TextCodeExcerptsModal';
import { getTeamUuid } from '../../../../utils/cookies';
import TextTranscriptionUploadModal from '../../../../Components/Text/TextTranscriptionModal';
import UpgradedLoader from '../../../../Components/UpgradedLoader';
import { getAiCodes } from '../../../../Requests/AICodeFinder';
import AICodeFinder from '../../../../Components/AICodeFinder/AICodeFinder';
import AICodeView from '../../../../Components/AICodeFinder/AICodeView';
import TranscriptionCodeView from '../../../../Components/TranscriptionCodeFinder.js/TranscriptionCodeView';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function DetailProject() {
    const { project_uuid } = useParams();
    const { t } = useTranslation();
    const [project, setProject] = useState(null);
    const [projectSlideover, setProjectSlideOver] = useState(null);
    const [uploadAudioModal, setUploadAudioModal] = useState(null);
    const [textCodes, setTextCodes] = useState(null)
    const [currentTab, setCurrentTab] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!project_uuid) {
            //navigate("/projects")
        } else {
            getProject(project_uuid).then((res) => {
                setProject(res.data);
            }).catch((error) => {

            });
        }
    }, [project_uuid]);

    let tabs = [
        { name: t("DetailProjects.AnalysisTab"), href: '#', current: currentTab === 0, id: 0, visible: true },
        { name: t("DetailProjects.ProjectGPT"), href: '#', current: currentTab === 1, id: 1, visible: true },
        { name: t("DetailProjects.Project codes"), href: '#', current: currentTab === 2, id: 2, visible: true },
        { name: t("DetailProjects.Coder"), href: '#', current: currentTab === 3, id: 3, visible: true },
        //{ name: t("AiCodeDiscovery.Title"), href: '#', current: currentTab === 4, id: 4, visible: (process.env.REACT_APP_BETA_USERS.split(",")).includes(getTeamUuid()) },
        //{ name: t("AiCodeFinder.Title"), href: '#', current: currentTab === 5, id: 5, visible: (process.env.REACT_APP_BETA_USERS.split(",")).includes(getTeamUuid()) },
        { name: t("AiCodeDiscovery.Title"), href: '#', current: currentTab === 4, id: 4, visible: true },
        { name: t("AiCodeFinder.Title"), href: '#', current: currentTab === 5, id: 5, visible: true },
    ]



    const handleNewFileUpload = (type) => {
        if (type === "Audio") {
            setUploadAudioModal(<NewAudioFileUploadModal handleClose={() => { setUploadAudioModal(null); }} project={project} />);
        } else if (type === "Pdf") {
            setUploadAudioModal(<UploadFileModal handleClose={() => { setUploadAudioModal(null); }} />);
        } else if (type == "Transcription") {
            setUploadAudioModal(<TextTranscriptionUploadModal handleClose={() => { setUploadAudioModal(null); }} />);
        }
    };

    const handleChangeTab = (e) => {
        let tab_index = e;
        if (tab_index == 3) {
            navigate("/text_coder/" + project_uuid)
        } else {
            setCurrentTab(e)
        }
    }

    useEffect(() => {
        if (!textCodes) {
            getTextCodes(project_uuid).then((res) => {
                setTextCodes(res.data)
            }).catch((error) => {

            });
        }
    }, [])



    function handleUpdateCode(updatedCode) {
        setTextCodes(prevCodes => prevCodes.map(code => code.uuid === updatedCode.uuid ? updatedCode : code));
    }
    function handleAddNewCode(newCode) {
        setTextCodes(prevCodes => [...prevCodes, newCode]);
    }


    return (
        <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center'>
            <div className='flex flex-col w-full justify-center items-center px-4'>
                {project && <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8'>
                    <SectionHeader
                        title={project.name}
                        subtitle={project.description}
                        actionTitle="Edit"
                        actionFunction={() => { setProjectSlideOver(<CreateEditProjectSlideover handleClose={() => { setProjectSlideOver(null); }} project={project} />); }}
                        breadcrubs={[{ name: t('Projects.Title'), href: "/projects" }]}
                    />
                </div>}

                <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8 my-8'>
                    <Card>
                        <div className='flex flex-col md:flex-row justify-between gap-2'>
                            <div className="sm:hidden">
                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                    onChange={(e) => handleChangeTab(Number(e.target.value))}
                                    value={currentTab}
                                >
                                    {tabs.map((tab) => (
                                        <option key={tab.id} value={tab.id}>{tab.name}</option>
                                    ))}

                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <nav className="flex space-x-4" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        tab.visible && <button
                                            key={tab.id}
                                            href={tab.href}
                                            className={classNames(
                                                tab.current ? 'bg-black text-white' : 'text-gray-500 hover:text-gray-700',
                                                'rounded-md px-3 py-2 text-sm font-medium'
                                            )}
                                            aria-current={tab.current ? 'page' : undefined}
                                            onClick={() => handleChangeTab(tab.id)}
                                        >
                                            {tab.name}
                                        </button>
                                    ))}
                                </nav>
                            </div>

                            <div className='flex justify-between'>

                                <button className='border border-black bg-white text-black rounded-md px-4 py-2 font-bold text-sm w-full md:w-fit'
                                    type='button'
                                    onClick={() => {
                                        /*setUploadAudioModal(<NewAudioFileUploadModal handleClose={() => { setUploadAudioModal(null) }} project={project} */
                                        setUploadAudioModal(<UploadFileWithTypes handleClose={() => { setUploadAudioModal(null) }} handleNewFileUpload={handleNewFileUpload} />)
                                    }}
                                >
                                    <div className='w-full flex justify-center items-center gap-2 whitespace-nowrap'>
                                        <DocumentArrowUpIcon className='w-6 h-6' />
                                        {t("DetailProjects.UploadFile")}
                                    </div>
                                </button>

                            </div>
                        </div>
                        {currentTab == 0 && <div>

                            {project?.analysis?.length > 0 ? <div className='grid grid-cols-1 lg:grid-cols-2 py-4 justify-evenly gap-12 items-center'>

                                {project?.analysis?.map((current_analysis) => {
                                    return (<AnalisysCard meeting={current_analysis} key={current_analysis.uuid} />)
                                })}

                            </div> :

                                (project == null ? <UpgradedLoader className="animate-pulse" w={8} h={8} bg="bg-black"></UpgradedLoader> :
                                    <div className='w-full py-12 mt-4 mb-2 rounded-md bg-white p-4 flex flex-col items-center gap-8 flex-grow'>
                                        <div className='text-xl font-bold'>
                                            {t('DetailProjects.This project has no files yet')}
                                        </div>
                                        <div className='text-base text-gray-500 '>
                                            {t('DetailProjects.Add a file to start')}

                                        </div>
                                        <img src={empty_file_cabinet} className=' h-96 aspect-square mt-2'></img>
                                        <button className='border border-black bg-black text-white text-xl rounded-md px-4 py-2 '
                                            type='button'
                                            onClick={() => {
                                                /*setUploadAudioModal(<NewAudioFileUploadModal handleClose={() => { setUploadAudioModal(null) }} project={project} */
                                                setUploadAudioModal(<UploadFileWithTypes handleClose={() => { setUploadAudioModal(null) }} handleNewFileUpload={handleNewFileUpload} />)
                                            }}
                                        >
                                            <div className='w-full flex justify-center items-center gap-2 whitespace-nowrap'>
                                                <DocumentArrowUpIcon className='w-6 h-6' />
                                                {t("DetailProjects.UploadFile")}
                                            </div>
                                        </button>
                                    </div>
                                )
                            }
                        </div>}
                        {currentTab == 1 && <div>
                            <div className='grid grid-cols-1  py-4 justify-evenly gap-12 items-center'>
                                <ProjectGpt project={project} />
                            </div>
                        </div>}
                        {currentTab == 2 && <div>
                            {textCodes?.length > 0 ?
                                <div className='flex flex-col gap-4 mt-4'>
                                    {textCodes.map((textCode) => {
                                        return (
                                            <div className='flex flex-col rounded-md px-4 py-4' key={textCode.uuid} style={{ backgroundColor: textCode.color }}>
                                                <span className='font-bold flex items-center gap-4'><div className=' bg-black rounded-full text-white text-sm w-8 h-8 flex justify-center items-center'>{textCode?.excerpts?.length || 0}</div>{textCode.name}</span>
                                                <span className='text-gray-700 text-sm mt-2'>{textCode.description}</span>
                                                <div className='flex justify-end gap-4'>
                                                    <button className='bg-white text-black rounded-md px-4 py-2 text-sm'
                                                        onClick={() => {
                                                            setUploadAudioModal(<TextCodeModal code={textCode} handleClose={() => { setUploadAudioModal(null) }} handleUpdateCode={handleUpdateCode} handleAddNewCode={handleAddNewCode} />)
                                                        }}
                                                    >
                                                        {t('DetailProjects.Edit')}
                                                    </button>
                                                    <button className='bg-black text-white rounded-md px-4 py-2 text-sm'
                                                        onClick={() => {
                                                            setUploadAudioModal(<TextCodeExcerptsModal handleClose={() => { setUploadAudioModal(null) }} code={textCode} />)
                                                        }}
                                                    >

                                                        {t('DetailProjects.View entries')}
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className='w-full flex justify-center mt-2'>
                                        <button className='border border-black bg-black text-white text-sm rounded-md px-4 py-2 '
                                            type='button'
                                            onClick={() => {
                                                /*setUploadAudioModal(<NewAudioFileUploadModal handleClose={() => { setUploadAudioModal(null) }} project={project} */
                                                setUploadAudioModal(<TextCodeModal handleClose={() => { setUploadAudioModal(null) }} handleUpdateCode={handleUpdateCode} handleAddNewCode={handleAddNewCode} />)
                                            }}
                                        >
                                            <div className='w-full flex justify-center items-center gap-2 whitespace-nowrap'>
                                                <TagIcon className='w-6 h-6' />
                                                {t("DetailProjects.Add code")}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className='w-full py-12 mt-4 mb-2 rounded-md bg-white p-4 flex flex-col items-center gap-8 flex-grow'>
                                    <div className='text-xl font-bold'>{t('DetailProjects.This project has no codes yet')}</div>
                                    <div className='text-base text-gray-500 '>{t('DetailProjects.Add a new code to get started')}</div>
                                    <img src={text_coder} className=' h-96 aspect-square mt-2'></img>
                                    <button className='border border-black bg-black text-white text-xl rounded-md px-4 py-2 '
                                        type='button'
                                        onClick={() => {
                                            /*setUploadAudioModal(<NewAudioFileUploadModal handleClose={() => { setUploadAudioModal(null) }} project={project} */
                                            setUploadAudioModal(<TextCodeModal handleClose={() => { setUploadAudioModal(null) }} handleUpdateCode={handleUpdateCode} handleAddNewCode={handleAddNewCode} />)
                                        }}
                                    >
                                        <div className='w-full flex justify-center items-center gap-2 whitespace-nowrap'>
                                            <TagIcon className='w-6 h-6' />
                                            {t("DetailProjects.Add code")}
                                        </div>
                                    </button>
                                </div>}
                        </div>}
                        {currentTab == 4 &&
                            <>
                                <AICodeView project={project} setUploadAudioModal={setUploadAudioModal} handleUpdateCode={handleUpdateCode} handleAddNewCode={handleAddNewCode} />
                            </>
                        }
                        {currentTab == 5 &&
                            <>
                                <TranscriptionCodeView project={project} setUploadAudioModal={setUploadAudioModal} handleUpdateCode={handleUpdateCode} handleAddNewCode={handleAddNewCode} textCodes={textCodes} />
                            </>
                        }

                    </Card>
                </div>
            </div >

            {projectSlideover}
            {uploadAudioModal}
        </div >
    )
}

export default DetailProject
